.stem-boundary {
  position: relative;
  width: 300px; /* Adjust as needed */
  height: 300px; /* Adjust as needed */
  border: 1px solid #ccc;
  overflow: hidden;
}

.stem-ball {
  position: absolute;
  background-color: #007bff; /* Change color as needed */
  border-radius: 50%;
  transition: all 0.3s ease-in-out; /* Smooth transition */
}
