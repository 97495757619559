.container {
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light grey background for the container */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    width: 80%;
    max-width: 600px;
  }
  
  .header { /* Assuming the class for the banner is 'header' */
    background-color: #333; /* Dark background for contrast */
    color: #fff; /* White text */
    padding: 10px 20px;
    border-radius: 8px 8px 0 0; /* Rounded corners at the top */
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  
  li {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  }
  
  .dmButton {
    padding: 6px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .dmButton:hover {
    background-color: #45a045;
    transform: translateY(-2px);
  }
  
  .dmButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.4), 0 0 0 4px #4CAF50;
  }
  
  /* Responsive styling for mobile devices */
  @media (max-width: 600px) {
    .container {
      width: 95%;
      padding: 15px;
    }
  
    li {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .dmButton {
      margin-top: 10px;
      width: 100%;
    }
  }
  