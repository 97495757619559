/* Instagram-inspired Base Styles */
:root {
    --background-color: #fafafa; /* Instagram's classic background */
    --primary-color: #3897f0; /* Instagram's button and link color */
    --text-color: #262626; /* Darker text for better legibility */
    --border-color: #dbdbdb; /* Soft border color for a gentle division */
    --button-bg-color: #3897f0; /* Button background */
    --button-text-color: #ffffff; /* Button text color */
    --progress-bar-color: #3897f0; /* Progress bar color, matching Instagram's theme */
    --shadow-color: rgba(0, 0, 0, 0.1); /* For adding depth */
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  /* Enhancing Video Upload and View Container */
  .videoUploadAndView {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 640px; /* More Instagram-like width */
    margin: 40px auto;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 1px 3px var(--shadow-color);
  }
  
  /* Stylizing Video Container */
  .videosContainer {
    max-height: 80vh; /* Adjust the height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 16px;
    padding: 20px;
  }
  
  .videoItem {
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    overflow: hidden;
  }
  
  video {
    width: 100%;
    height: auto;
    border-bottom: 1px solid var(--border-color); /* Separating media from info */
  }
  
  /* Video Info Section */
  .videoInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #fafafa; /* Lighter background for info section */
  }
  
  .videoInfo button {
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .videoInfo button:hover {
    background-color: darken(var(--button-bg-color), 10%);
  }
  
  /* Upload Button and Progress Bar Styling */
  .uploadButton, .loadMore {
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .uploadButton:hover, .loadMore:hover {
    opacity: 0.8;
  }
  
  .uploadProgress {
    width: 100%;
    background-color: #e0e0e0;
    height: 4px; /* Slimmer progress bar for subtlety */
    margin: 20px 0;
    border-radius: 2px;
  }
  
  .uploadProgress::before {
    content: '';
    display: block;
    background-color: var(--progress-bar-color);
    width: var(--upload-progress, 0%);
    height: 100%;
    transition: width 0.3s ease;
  }
  
  /* Enhancing the Loading State */
  .loadingMore {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: var(--text-color);
  }
  /* Instagram-inspired Detailed Styles Extension */
  
  /* General Animation and Transition Enhancements */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    transition: all 0.25s ease;
  }
  
  /* Focus and Hover States for Interactivity */
  button,
  input[type="submit"],
  input[type="button"],
  .label-upload {
    cursor: pointer;
    outline: none;
    &:hover,
    &:focus {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px var(--shadow-color);
    }
  }
  
  /* Label Upload Enhancements */
  .label-upload {
    display: inline-block;
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    padding: 8px 15px;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s;
    margin: 10px 0;
    cursor: pointer;
  }
  
  .label-upload:hover {
    background-color: darken(var(--button-bg-color), 5%);
    transform: scale(1.05);
  }
  
  /* Input Field Styles */
  .input-text {
    width: 100%;
    padding: 8px 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background-color: #fff;
    transition: border-color 0.3s;
    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px rgba(var(--primary-color), 0.2);
    }
  }
  
  /* Responsive Video Grid Adjustments */
  @media (max-width: 768px) {
    .videosContainer {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
  }
  
  /* Load More Button Animation */
  .loadMore {
    animation: pulseAnimation 2s infinite;
  }
  
  @keyframes pulseAnimation {
    0% {
      transform: scale(1);
      box-shadow: none;
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 10px var(--shadow-color);
    }
    100% {
      transform: scale(1);
      box-shadow: none;
    }
  }
  
  /* Upload Progress Animation */
  .uploadProgress::before {
    animation: loadingProgress 2s ease-in-out infinite;
  }
  
  @keyframes loadingProgress {
    0% {
      width: 0;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }
  
  /* Comment and Like Section */
  .comment-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .comment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }
  
  .comment-text {
    flex: 1;
  }
  
  .like-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
  
  /* Responsive Adjustments for Smaller Devices */
  @media (max-width: 480px) {
    .videoUploadAndView {
      margin: 20px 10px;
      padding: 10px;
    }
  
    .videoItem {
      border-radius: 6px;
    }
  
    .videoInfo,
    .post-header,
    .post-icons {
      padding: 8px;
    }
  
    .icon,
    .videoInfo button {
      padding: 4px;
      width: 20px;
      height: 20px;
    }
  }
  
  /* Enhancement for Visual Indicators */
  .visual-indicator {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: var(--primary-color);
      animation: loadIndicator 3s ease-in-out forwards;
    }
  }
  
  @keyframes loadIndicator {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  /* Enhancement for Night Mode Toggle */
  .night-mode-toggle {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid var(--border-color);
    padding: 5px;
    border-radius: 50%;
    z-index: 100;
    cursor: pointer;
    box-shadow: 0 2px 4px var(--shadow-color);
  }
  
  .night-mode-toggle:hover {
    background-color: var(--primary-color);
    color: var(--button-text-color);
  }
  
  /* Dynamic Shadows for Depth */
  .dynamic-shadow {
    box-shadow: 0 2px 4px var(--shadow-color);
    &:hover {
      box-shadow: 0 4px 8px var(--shadow-color);
    }
  }
  .skeleton-loader {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    background-color: #e0e0e0;
    overflow: hidden;
    position: relative;
    animation: shimmer 2s infinite linear;
  }
  
  .skeleton-loader::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transform: translateX(100%);
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  
  .skeleton-text {
    height: 20px;
    margin: 5px 0;
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .skeleton-video {
    width: 100%;
    height: 200px;
    background-color: #ccc;
    margin-bottom: 10px;
  }
  .button-3d:hover {
    transform: translateY(-4px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  .modal {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 10px;
    animation: modalAppear 0.4s ease-out;
  }
  
  @keyframes modalAppear {
    from {transform: translateY(-50px); opacity: 0;}
    to {transform: translateY(0); opacity: 1;}
  }
  .videosContainer::-webkit-scrollbar {
    width: 8px;
  }
  
  .videosContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .videosContainer::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .videosContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  .infinite-scroll-item {
    animation: fadeIn 1s;
  }
  @media (max-width: 600px) {
    .videoUploadAndView, .modal-content {
      width: 95%;
    }
  
    .skeleton-video {
      height: 150px;
    }
  }
  .tooltip:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1;
  }
  body.dark-mode {
    transition: background-color 0.5s ease, color 0.5s ease;
    background-color: #181818;
    color: #fff;
  }
  
  .night-mode-toggle {
    transition: background-color 0.5s ease, transform 0.5s ease;
  }
  .input-text:focus, .input-text:invalid {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  .buttonDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100vh;
    background: 
        linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333),
        linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333);
    background-color: #111; /* fallback color */
    background-size: 20px 20px;
    animation: animateBackground 1s linear infinite;
}

@keyframes animateBackground {
    0% {
        background-position: 0 0, 0 0;
    }
    100% {
        background-position: 40px 40px, 20px 20px;
    }
}
