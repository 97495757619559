
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  background: 
      linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333),
      linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333);
  background-color: #111; /* fallback color */
  background-size: 20px 20px;
  animation: animateBackground 1s linear infinite;
}

@keyframes animateBackground {
  0% {
      background-position: 0 0, 0 0;
  }
  100% {
      background-position: 40px 40px, 20px 20px;
  }
}

  .loader {
    color: #4a4a4a;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    padding: 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }
  
  .words {
    overflow: hidden;
  }
  
  .word {
    display: block;
    height: 100%;
    padding-left: 6px;
    color: #299fff;
    animation: cycle-words 5s infinite;
  }
  
  @keyframes cycle-words {
    10% {
      -webkit-transform: translateY(-105%);
      transform: translateY(-105%);
    }
  
    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  
    35% {
      -webkit-transform: translateY(-205%);
      transform: translateY(-205%);
    }
  
    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  
    60% {
      -webkit-transform: translateY(-305%);
      transform: translateY(-305%);
    }
  
    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
  
    85% {
      -webkit-transform: translateY(-405%);
      transform: translateY(-405%);
    }
  
    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }
  