.chatContainer {
  max-width: 600px;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff; /* Maintain white background */
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  color: #000; /* Set default text color to black */
}

.chatHeader {
  font-size: 24px;
  color: #000; /* Black text for header */
  margin-bottom: 10px;
}

.messagesContainer {
  height: 500px;
  overflow-y: auto;
  background-color: #f9f9fb; /* Slightly off-white for contrast */
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 8px;
  color: #000; /* Black text for messages */
}

.message {
  background-color: #e9e9eb; /* Light grey for messages */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-left: 5px solid #007bff; /* Blue accent border */
  color: #000; /* Black text for messages */
  word-wrap: break-word;
}

.message:nth-child(odd) {
  background-color: #f3f3f5; /* Slightly different light grey for alternating messages */
}

.messageForm {
  display: flex;
  justify-content: space-between;
}

.messageInput {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff; /* White background for input field */
  color: #000; /* Black text for input */
}

.sendButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.sendButton:hover {
  background-color: #0056b3;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f50202;
  border: none;
  color: #fff; /* Adjust color based on your design */
  font-size: 24px;
  cursor: pointer;
  outline: none;
}

.chatContainer {
  position: relative; /* This ensures that the closeButton is positioned relative to the chatContainer */
}