.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.signup-form,
.login-form {
  display: flex;
  flex-direction: column;
}

input,
.signup-button,
.login-button {
  margin: 5px 0px;
}

.name-input {
  width: calc(100% - 24px); /* Adjust width to account for padding and borders */
  padding: 12px; /* Increased padding for better text visibility and comfort */
  margin-top: 15px; /* Adjust margin as needed */
  background-color: #fff; /* Pure white background */
  border: 1px solid #ced4da; /* Subtle border color */
  border-radius: .375rem; /* Soft rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transitions for interactions */

  font-size: 1rem; /* Adequate font size for readability */
  color: #495057; /* Slightly dark text color for contrast against background */
}

.name-input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #80bdff; /* Highlight color for focus */
  background-color: #e9ecef; /* Slightly lighter background to indicate focus */
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* Blue glow effect to indicate focus */
}

.signup-button,
.login-button {
  cursor: pointer;
}

.blink-arrow {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}