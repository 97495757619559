/* Basic Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #0f0f0f;
  color: #ffffff; /* Updated text color for better readability */
}

/* Carbon Fiber Background with Moving Gradient Effect */
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  background: 
      linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333),
      linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333);
  background-color: #111; /* fallback color */
  background-size: 20px 20px;
  animation: animateBackground 1s linear infinite;
}

@keyframes animateBackground {
  0% {
      background-position: 0 0, 0 0;
  }
  100% {
      background-position: 40px 40px, 20px 20px;
  }
}




/* Main Container */
.main-container {
  display: flex;
  justify-content: center;    
  align-items: center;
  height: 100vh;
  text-align: center;
}

/* Card Styling */
.card {
  background: rgba(255, 255, 255, 0.08); /* Slightly more visible */
  backdrop-filter: blur(15px); /* Increased blur */
  border-radius: 15px;
  padding: 40px;
  width: 90%;
  max-width: 600px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@keyframes buttonGlow {
  0%, 100% {
    box-shadow: 0 0 10px #fbfbfb;
  }
  50% {
    box-shadow: 0 0 20px #f0f0f0;
  } 
}

button {
  background-color: #de4848; /* Default grey background */
  color: #000000; /* Changed text color for visibility */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover:not(:disabled) {
  animation: buttonGlow 1.5s infinite alternate;
  transform: scale(1.1); /* More noticeable hover effect */
}

button:disabled {
  background: #555;
  cursor: not-allowed;
}
