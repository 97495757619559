body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  background: 
      linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333),
      linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333);
  background-color: #111; /* fallback color */
  background-size: 20px 20px;
  animation: animateBackground 1s linear infinite;
}

@keyframes animateBackground {
  0% {
      background-position: 0 0, 0 0;
  }
  100% {
      background-position: 40px 40px, 20px 20px;
  }
}

.error-message {
  color: #dc3545; /* Make error message color consistent with danger or error indication */
  margin-bottom: 20px;
  font-weight: bold; /* Make text slightly bolder for better readability */
}

.login-button, .reset-password-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 20px; /* More pronounced rounded corners for buttons */
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.reset-password-button {
  background-color: #f8f9fa; /* Lighter color for distinction */
  color: #007bff; /* Use primary color for text */
}

.login-button:hover, .reset-password-button:hover {
  opacity: 0.8;
  transform: scale(1.05); /* Slight increase in size for interactive feedback */
}

@media (max-width: 768px) {
  .login-container {
    width: 90%;
    padding: 20px;
  }

  .login-form input, .login-button, .reset-password-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
/* Define custom input styles */
.custom-input {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Apply styles to specific inputs */
.custom-input[type="email"],
.custom-input[type="password"] {
  background-color: #f8f9fa; /* Lighter background for email and password inputs */
}

/* Apply styles to the submit buttons */
.login-button, .reset-password-button, .signup-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  margin-top: 10px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 20px; /* More pronounced rounded corners for buttons */
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

/* Hover effect for buttons */
.login-button:hover, .reset-password-button:hover, .signup-button:hover {
  opacity: 0.8;
  transform: scale(1.05); /* Slight increase in size for interactive feedback */
}

/* Apply custom styles to the form container */
.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Additional styles for the form */
.form-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

