/* Base settings to set up font and background properties */
.profile-page body, .profile-page html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* General settings for the profile container */
.profile-page .profile-container {
  width: 90%;
  max-width: 600px;
  margin: 50px auto;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

/* Hover effect to slightly enlarge the form */
.profile-page .profile-container:hover {
  transform: scale(1.03);
}

.profile-page h2 {
  text-align: center;
  color: #333;
}

/* Styles for input fields and text areas */
.profile-page input[type="text"],
.profile-page textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  resize: none; /* Disable resizing for text areas */
}

/* Enhancing the file input style */
.profile-page input[type="file"] {
  background-color: #f2f2f2;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-page input[type="file"]:hover {
  background-color: #ddd;
}

/* Styling labels for better visibility */
.profile-page label {
  color: #333;
  font-weight: bold;
  display: block;
  margin-top: 20px;
  text-shadow: 1px 1px 0px #fff;
}

/* Button styling for a more engaging look */
.profile-page button {
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 14px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.profile-page button:hover {
  background-color: #45a049;
}

/* Styling for the image preview */
.profile-page img {
  max-width: 100%;
  display: block;
  margin: 10px auto;
  border-radius: 50%; /* Circular images */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Crop container specific styles */
.profile-page .ReactCrop {
  position: relative;
  max-width: 100%;
  margin: 20px auto;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
}

.profile-page .ReactCrop--crop-selection {
  border: 2px dashed #666;
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .profile-page .profile-container {
    width: 95%;
    margin: 20px auto;
  }

  .profile-page input[type="file"] {
    padding: 10px;
  }

  .profile-page button {
    width: 100%;
    padding: 12px;
  }
}
